import React from 'react';

import Boilerplate from '../components/boilerplate';
import Page from '../components/page';
import { usePageData } from '../data';
import { Template } from '../types/templates';

const PageTemplate: Template = ({ pageContext: { id } }) => {
  const { data } = usePageData(id);

  return (
    <Boilerplate seoId={data.seo.id} pageId={id}>
      <Page data={data} />
    </Boilerplate>
  );
};

export default PageTemplate;
